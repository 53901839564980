@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html, body {
    font-family: 'Open Sans', sans-serif;
}
.iso-dashboard-container {
    background-image: url("./images/dashboard-background-greyscale-min.jpeg");
    background-position: center;
    background-size: cover;
    padding:24px;
}
.iso-dashboard-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.iso-dashboard-title {
    font-size: 25px;
    font-weight: 700;
    line-height: 34px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0;
}
.iso-dashboard-tiles-container {
    display: flex;
    flex-flow: row wrap;
    gap: 24px;
}
.iso-dashboard-tile {
    background-image: linear-gradient(rgba(0,0,0, 0.70) 0%, rgba(0,0,0, 0.70) 100%), url("./images/dashboard-tile-background.jpeg");
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(25% - (1 - 1/4) * 24px);
    justify-content: space-between;
    border-radius: 5px;
    padding: 24px;
    gap: 16px;
    box-shadow: 0px 15px 10px 10px rgba(0,0,0, 0.75);
    position: relative;
}
.iso-dashboard-tile-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    left: 0;
    transform: translateX(-100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 24px;
    gap: 16px;
    /* background-color: rgba(255, 255, 255); */
    border-radius: 5px;
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: opacity 350ms ease-in-out 350ms;
    animation: slide-in-left 350ms forwards;
}
.iso-dashboard-tile:hover .iso-dashboard-tile-overlay {
    opacity: 1;
}
@keyframes slide-in-left {
    100% { transform: translateX(0%); }
}
.overlay-hours-completed {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #FFFFFF;
    text-transform: uppercase;
}
.overlay-view-hours {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-decoration: none;
    background-color: #DF2928;
    color: #FFFFFF;
    display: inline-block;
    padding: 8px 16px;
    border-radius: 2px;
}
.overlay-view-hours:hover {
    background-color: #FFFFFF;
    color: #DF2928;
}
.iso-dashboard-tile-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0;
}
.iso-dashboard-tile-progress {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.iso-dashboard-title-progressBar-outer {
    display: flex;
    height: 5px;
    width: 100%;
    background-color: #555555;
    border-radius: 0px;
}
.iso-dashboard-title-progressBar-progress {
    height: 5px;
    background-color: #FEDE17;
}
.iso-dashboard-tile-progress-percentage {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #FFFFFF;
    margin: 0
}
.custom-spinner
{
  position: fixed;
  left: 50%;
  top: 50%;
}

/* Expanded View */
.expanded-version-dashboard-container {
    padding:48px;
    /*height: 500px;*/ 
    position: relative;
    z-index: 0;
    background-image: none;
}
.expanded-version-dashboard-container:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 500px;
    width: 100%;
    background-image: url("./images/dashboard-background-greyscale-min.jpeg");
    z-index: -1;
    background-size: cover;
    background-position: center;
}
.expanded-version-dashboard-container:after {
    background: linear-gradient(180deg, rgba(0,0,0,0.05) 0%, rgba(255,255,255,1) 100%);
    content: "";
    height: 500px;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
}
.expanded-version-content-wrapper {
    gap: 48px;
    position: relative;
    z-index: 2;
}
.expanded-version-title {
    font-size: 38px;
}
.expanded-version {
    position: relative;
    box-shadow: 0px 10px 10px 5px rgba(0,0,0, 0.30);
    flex-direction: row;
    align-items: center;
}
.expanded-version:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(59, 59, 59, 0.7);
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 350ms ease-in-out;
}
.expanded-version-active {
    border: 1px solid #FFFFFF;
}
.expanded-version-active:before {
    background-color: rgba(255, 255, 255, 0);
}
.expanded-version:hover:before {
    background-color: rgba(255, 255, 255, 0);
}
.iso-dashboard-expanded-text-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1 1 calc(66.667% - 16px);
}
.iso-dashboard-expanded-text-content-divider {
    height: 1px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.25);
}
.iso-dashboard-hours-completed {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #FFFFFF;
    margin: 0   
}
/* Data Table */
.iso-dashboard-data-table-wrapper {
    background-color: #FFFFFF !important;
    padding: 24px !important;
    box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.3) !important;
    border-radius: 5px !important;
    color: #000000 !important;
}
.iso-dashboard-data-table {
    display: table !important;
    width: 100% !important;
    border-collapse: collapse !important;
    border-spacing: 0 !important;
}
.iso-dashboard-data-table thead tr th {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #212A5C;
    padding: 16px 12px 16px 12px;
    border: none;
    font-family: 'Open Sans', sans-serif;
}
.iso-dashboard-data-table tbody tr {
    border: none;
}
.iso-dashboard-data-table tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,0.06);
}
.iso-dashboard-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0,0,0,0.01);
}
.iso-dashboard-data-table tbody tr td {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
    padding: 16px 12px 16px 12px;
    border: none;
    font-family: 'Open Sans', sans-serif;
}

/* Graph */
.iso-dashboard-graph-container {
    background-color: #FFFFFF !important;
    padding: 24px !important;
    box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.3) !important;
    border-radius: 5px !important;
    color: #000000 !important;
    border: none;
}
.iso-dashboard-graph-top-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 16px;
}
.iso-dashboard-graph-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #212A5C;
    margin: 0;
}
.iso-dashboard-data-selector-button select {
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #868686;
}
.export-button {
    background-color: #212a5c;
}
/* Progress Circle */
.iso-dashboard-tile-progress-ring-container {
    position:relative;
    width: 50px;
    height: 50px;
}

.iso-dashboard-doughnut-label {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #AAA;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 11px;
}

@media (max-width: 992px) {
    .iso-dashboard-tile {
    flex: 0 0 calc(50% - (1 - 1/2) * 24px);
}
}